import pramod from "@/../assets/images/pramod.png"
import beereshs from "@/../assets/images/beereshs.png"
import rahul from "@/../assets/images/rahul.png"

import brand_new from "@/../assets/images/brand_new.jpeg"
import openBox from "@/../assets/images/open_box.jpeg"
import veryGood from "@/../assets/images/very_good.jpeg"
import good from "@/../assets/images/good.jpeg"
import acceptable from "@/../assets/images/acceptable.jpeg"
import defective from "@/../assets/images/defective.jpeg"
import notTest from "@/../assets/images/not_tested.jpeg"

export const userNameRegex = /^[a-z]+(?:_+[a-z]+)*$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const termsOfPurchase = {
  title: "Terms of Purchase",
  data: [
    {
      title: "Terms of Purchase not available."
    }
  ]
}

export const itemConditions = [
  {
    image: brand_new,
    title: "Brand New",
    description: "A brand-new, unused, unopened item in its original packaging, with all original packaging materials included. Packaging might have minor scratches or damages. Since it is seal packed, functional &amp; physical condition of the item is not checked by Bulk4Traders team"
  },

  {
    image: openBox,
    title: "Open Box",
    description: "An apparently untouched item in perfect condition and fully functional. The original packaging is intact but could have minor scratches or damages. There are absolutely no signs of wear on the item.The product has been tested by the Bulk4Traders team."
  },
  {
    image: veryGood,
    title: "Very Good",
    description: "The product is well-cared-for and is fully functional but may show minor physical or cosmetic blemishes and/ or the item may have some accessories missing. The packaging might have been replaced to protect the item. The product has been tested by the Bulk4Traders team"
  },
  {
    image: good,
    title: "Good",
    description: "The item shows normal marks from consistent use, but it remains in good condition and works fully. It may show other signs of previous use and/ or the item may have some accessories missing. The packaging might be missing or might have major damages. The product has been tested by the Bulk4Traders team."
  },
  {
    image: acceptable,
    title: "Acceptable",
    description: "The product may have minor functional issues and/ or the item is fairly worn. Signs of wear can include aesthetic issues such as scratches, dents, worn corners and cracks/damage on body. The item may have identifying markings on it or show other signs of previous use. Packaging may or may not be present and packaging condition may be bad. The product has been tested by the Bulk4Traders team."
  },
  {
    image: defective,
    title: "Defective",
    description: "The product may be functionally defective and/ or physically damaged. Packaging may or may not be present and packaging condition may be bad. The product has been tested by the Bulk4Traders team."
  },
  {
    image: notTest,
    title: "Not Tested",
    description: "These items have not been inspected by Bulk4Traders team or have been physically inspected but not functionally checked. These are being sold in “As-is” condition. Some of these items could be working order while some may not be fully functional. These may not come with original packaging, manuals and/or supplementary accessories such as batteries and chargers."
  }
]

export const FAQ = {
  title: 'Applying and Logging In. How do I register as a buyer? What is the fee for registration?',
  data: [
    {
      title: "Q. How do I place a bid?",
      subtitle: "A. After logging in to Bulk4Traders marketplace, you will be able to navigate to any live auction in any marketplace you are registered in. The auction will provide all the information you need to place a bid i.e. lot size, lot location, few photographs of the inventory, condition of the inventory, the floor price, the current bid price, increments to bid, the buy now price, and the time the auction ends. You can obtain detailed information by line item detail by downloading the manifest."
    },
  ]
} 

export const feedBackOptions = [
  { label: "Lot too small", value: "Lot too small" },
  { label: "Lot too large", value: "Lot too large" },
  { label: "Logistics cost is too high", value: "Logistics cost is too high" },
  { label: "Lot mix not good", value: "Lot mix not good" },
  { label: "Manifest not clear", value: "Manifest not clear" },
  { label: "No grading details", value: "No grading details" },
  { label: "Pricing not attractive", value: "Pricing not attractive" }
]

export const sortOptions = [
  {
    title: "Sort by: Time Left",
    value: "end_date_l_h",
  },
  {
    title: "Sort by: Recent",
    value: "-created_at",
  },
  {
    title: "Price : High to Low",
    value: "-floor_price",
  },
  {
    title: "Price : Low to High",
    value: "+floor_price",
  },
]

export const moqSortOptions = [
  {
    title: "Sort by: Time Left",
    value: "end_date_l_h",
  },
  {
    title: "Sort by: Recent",
    value: "-created_at",
  },
  {
    title: "Price : High to Low",
    value: "-mrp",
  },
  {
    title: "Price : Low to High",
    value: "+mrp",
  },
]

export const scbLotSortOptions = [
  {
    title: "Sort by: Time Left",
    value: "end_date_l_h",
  },
  {
    title: "Price : High to Low",
    value: "price_h_l",
  },
  {
    title: "Price : Low to High",
    value: "price_l_h",
  },
]

export const biddingOptions = [
  {
    label: "Open",
    value: "Open",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  }
]

export const paymentStatus = {
  "Payment Pending": 'Pending',
  "Cancelled": 'Cancelled',
  "Payment Success": "Success"
}

export const manifestTableHeader = [
  { title: 'STOCK IMAGE', sortable: false, key: 'image_urls' },
  { title: 'DESCRIPTION', key: 'description' },
  { title: 'BRAND', key: 'brand' },
  { title: 'CATEGORY', key: 'category' },
  { title: 'MRP', key: 'mrp' },
  { title: 'Quantity', key: 'quantity' },
]

export const DocumentTypes = [
  "COI copy,Partnership Firm-Partnership Deed copy",
  "GST Registration",
  "Company PAN",
  "Shop and establishment",
  "Trade License",
  "Municipal / Local authority registration copy",
  "Udyog Aadhar"
]

export const lotEndpoints = [
  "/api/organizations/fetch_marketplace",
  "/api/external_service/get_marketplaces",
  '/all_bids_refersh',
  '/api/lot_publishes/filter',
  '/api/external_service/get_manifest_detail',
  '/api/external_service/search_lot',
  '/api/external_service/get_lot_details',
  '/api/external_service/lots_filter_options',
  '/api/lots/lot_filter_options',
  '/api/bids/buy_now_summary',
  '/api/organizations/',
  '/terms_of_purchase'
]

export const passwordResetEndpoints = [
  '/reseller/users/reset_password.json',
  '/reseller/users/change_password.json',
  '/reseller/users/verify_otp'
]

export const testimonials = [
  {
    name: "Pramod Chaudhari",
    desc: "Working with Blubirch since 5 years, overall experience is very nice. Their customer support is amazing, they always provide assistance, guidance and help until the shipment reaches the end user and after as well. Branded products are available in very good condition and at very affordable heavily discounted prices, have helped improve profitability of my bussiness.",
    location: "Mumbai",
    profileImg: pramod
  },
  {
    name: "Dharmesh Pattani",
    desc: "Extreme determination for buyers. The best thing about Blubirch is that they have gone to great lengths to support the buyers, I could make my purchases as per my requirement and capacity, load sizes are made flexible, and this have helped me a great deal in running my business smoothly and appropriate stocking of my inventories. Overall, very transparent and supportive.",
    location: "Rajkot",
    profileImg: beereshs
  },
  {
    name: "Rahul Sahu",
    desc: "Over 5 years of partnership, process of liquidation is commendable. Stock updates and customer support is remarkable in helping us through the entire buying journey. May it be stock pick up, payment follow ups or dispatch, their customer service has been impeccable in helping me through all the processes. We are happy to be associated with Blubirch, the company has helped us grow our business.",
    location: "New Delhi",
    profileImg: rahul
  }
]

export const subjects = [
  "Product Details Enquiry",
  "Product Grievance",
  "Product Price Enquiry",
  "Other Issues"
]

export const defaultError = "Something went wrong, Please try after sometime.";

export const statusList = {
  InProgress: 'In Progress',
  InProgress_2: 'InProgress',
  Expired: 'Expired',
  ClosedSuccess: 'Closed Success',
  NotStarted: 'Not Started'
}